import React from 'react';
import 'assets/style/button.scss';
import { Icons } from 'assets/icons/index';

type ButtonProps = {
  title?: string;
  type?: 'submit' | 'button' | 'reset';
  icon?: 'download' | 'retry';
  onClick?: (e?: React.MouseEvent<any>) => void;
  className?: string;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  title = '',
  type = 'button',
  disabled = false,
  className = '',
  icon = null,
  ...props
}): JSX.Element => {
  const iconSvg = {
    download: Icons.IconDownload({ size: 20, color: '#ffffff' }),
    retry: Icons.IconRetry({ size: 20, color: '#ffffff' })
  };

  const iconClass = {
    download: 'btn--download',
    retry: 'btn--filter'
  };

  return (
    <button {...props} disabled={disabled} className={className} type={type}>
      {icon && <i className={iconClass[icon]}>{iconSvg[icon]}</i>}
      {title}
    </button>
  );
};

export default Button;
