import React, { createContext, useReducer, useMemo } from 'react';
import { ContextValue, ProviderProps } from 'types/types';

export enum ProcessingState {
  NOT_STARTED,
  PROCESSING,
  FINISHED
}

export enum ActionKind {
  HANDLE_IMAGE,
  HANDLE_PROCESS,
  HANDLE_ERROR,
  HANDLE_CTA
}

export type State = {
  image: string | null | undefined;
  process: ProcessingState;
  error: string | null;
  cta: boolean;
};

const initialState: State = {
  image: null,
  process: ProcessingState.NOT_STARTED,
  error: null,
  cta: false
};

type Action =
  | { type: ActionKind.HANDLE_IMAGE; payload: string | null | undefined }
  | { type: ActionKind.HANDLE_PROCESS; payload: ProcessingState }
  | { type: ActionKind.HANDLE_ERROR; payload: string | null }
  | { type: ActionKind.HANDLE_CTA; payload: boolean };

const demoContext = createContext<ContextValue<State, Action>>({
  state: initialState,
  dispatch: (action) => console.error('Dispatched action outside of a demoContext provider', action)
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.HANDLE_IMAGE:
      return {
        ...state,
        image: action.payload
      };
    case ActionKind.HANDLE_PROCESS:
      return {
        ...state,
        process: action.payload
      };
    case ActionKind.HANDLE_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case ActionKind.HANDLE_CTA:
      return {
        ...state,
        cta: action.payload
      };
    default:
      return state;
  }
};

const DemoProvider: React.FC<ProviderProps> = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <demoContext.Provider value={contextValue}>{children}</demoContext.Provider>;
};

export { demoContext, DemoProvider };
