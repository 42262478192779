import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type RenderHelmetProps = {
  name: string;
};

// Component to update meta head dynamically
const RenderHelmet: React.FC<RenderHelmetProps> = ({ name }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  let { pathname } = useLocation();

  if (pathname === '/' + currentLanguage) {
    pathname = '/' + currentLanguage + '/landing';
  }

  return (
    <>
      <Helmet>
        <title>{t(`meta.${currentLanguage}.${pathname.substring(4)}.title`)}</title>
        <meta name="description" content={t(`meta.description`)} />
      </Helmet>
    </>
  );
};

export default RenderHelmet;
