import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languagesAvailable = ['en', 'fr'];

const i18n = use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'fr-FR': ['fr'],
      default: ['en']
    },
    debug: false,
    //load: 'languageOnly',
    supportedLngs: languagesAvailable,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
