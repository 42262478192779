export type MenuItem = {
  name: string;
  submenu?: LinkItem[];
  link?: string;
  class?: string;
};

export type LinkItem = {
  name: string;
  link: string;
};

export const defaultHeaderLinks: MenuItem[] = [
  {
    name: 'face-attributes',
    link: '/face-attributes'
  },
  {
    name: 'face-recognition',
    link: '/face-recognition'
  }
];

export const faceAttributestHeaderLinks: MenuItem[] = [
  {
    name: 'features',
    link: '/face-attributes#features'
  },
  {
    name: 'solutions',
    submenu: [
      {
        name: 'api-face-attributes',
        link: '/face-attributes/api'
      },
      {
        name: 'online-platform',
        link: '/face-attributes/online-platform'
      }
    ]
  },
  {
    name: 'pricing',
    link: '/face-attributes/pricing'
  },
  {
    name: 'demo',
    link: '/face-attributes#demo',
    class: 'menu__link--demo'
  },
  {
    name: 'contact',
    link: '/face-attributes#contact',
    class: 'menu__link--contact'
  }
];

export const faceRecognitiontHeaderLinks: MenuItem[] = [
  {
    name: 'features',
    link: '/face-recognition#features'
  },
  {
    name: 'solutions',
    submenu: [
      {
        name: 'api-face-recognition',
        link: '/face-recognition/api#search'
      },
      {
        name: 'online-platform',
        link: '/face-recognition/online-platform'
      }
    ]
  },
  {
    name: 'pricing',
    link: '/face-recognition/pricing'
  },
  // TODO: Leave it be, until Skippy says otherwise
  // {
  //   name: 'demo',
  //   link: '/face-recognition#demo',
  //   class: 'menu__link--demo'
  // },
  {
    name: 'contact',
    link: '/face-recognition#contact',
    class: 'menu__link--contact'
  }
];

export const languages = ['fr', 'en'];
