import React, { createContext, useMemo, useReducer } from 'react';
import { ContextValue, ProviderProps } from 'types/types';

/*
 Context & Provider to dispatch Service selected / pricing plan selected / data consumption amount
*/

export type Service = 'api' | 'sdk';
export type Plan = 'basic' | 'standard' | 'premium';
export type DataType = 'image' | 'video';

type State = {
  service: Service;
  plan: Plan;
  dataType: DataType;
  amount: number;
};

const initialState: State = {
  service: 'api',
  plan: 'standard',
  dataType: 'image',
  amount: 0
};

export enum ActionKind {
  HANDLE_SERVICE,
  HANDLE_PLAN,
  HANDLE_DATA_TYPE,
  HANDLE_AMOUNT
}

type Action =
  | { type: ActionKind.HANDLE_SERVICE; payload: Service }
  | { type: ActionKind.HANDLE_PLAN; payload: Plan }
  | { type: ActionKind.HANDLE_DATA_TYPE; payload: DataType }
  | { type: ActionKind.HANDLE_AMOUNT; payload: number };

const pricingContext = createContext<ContextValue<State, Action>>({
  state: initialState,
  dispatch: (action) =>
    console.error('Dispatched action outside of a pricingContext provider', action)
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.HANDLE_SERVICE:
      return {
        ...state,
        service: action.payload
      };
    case ActionKind.HANDLE_PLAN:
      return {
        ...state,
        plan: action.payload
      };
    case ActionKind.HANDLE_DATA_TYPE:
      return {
        ...state,
        dataType: action.payload
      };
    case ActionKind.HANDLE_AMOUNT:
      return {
        ...state,
        amount: action.payload
      };
    default:
      return state;
  }
};

const PricingProvider: React.FC<ProviderProps> = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <pricingContext.Provider value={contextValue}>{children}</pricingContext.Provider>;
};

export { pricingContext, PricingProvider };
