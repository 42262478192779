/* eslint-disable import/named */
import { Icons } from 'assets/icons';
import { TFunction } from 'i18next';
import { Images } from 'assets/images';

export const mediaData = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/wassabemobile',
    icon: Icons.Facebook({ size: 35, color: '#3b5998' })
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/wassabemobile',
    icon: Icons.Twitter({ size: 35, color: '#1DA1F2' })
  },
  {
    name: 'linkedin',
    href: 'https://fr.linkedin.com/showcase/blurit',
    icon: Icons.Linkedin({ size: 35, color: '#0e76a8' })
  },
  {
    name: 'github',
    href: 'https://github.com/wassafr/wis-api-samples',
    icon: Icons.Github({ size: 35, color: '#333' })
  }
];

export const otherSolutions = [
  {
    title: 'blurit',
    href: process.env.REACT_APP_URL_BLURIT,
    image: Images.Blurit
  },
  {
    title: 'wis',
    href: process.env.REACT_APP_URL_WIS,
    image: Images.Wis
  },
  {
    title: 'pixlytics',
    href: process.env.REACT_APP_URL_PIXLYTICS,
    image: Images.Picslytics
  }
];

export const navData = (t: TFunction) => [
  {
    title: `© ${new Date().getFullYear()} Wassa`,
    href: 'https://www.wassa.io',
    external: true
  },
  {
    title: t('footer.nav.terms'),
    href: '/terms-of-use',
    external: false
  },
  {
    title: t('footer.nav.privacy'),
    href: '/privacy-policy',
    external: false
  },
  {
    title: t('footer.nav.cookies'),
    href: '/cookies-policy',
    external: false
  }
];
