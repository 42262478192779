import React, { useEffect } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import useModal from 'components/modal/useModal';
import Modal from 'components/modal/Modal';
import { useLocation } from 'react-router-dom';
import CookiesModalContent from 'components/cookiesModal/CookiesModal';
import { useCookies } from 'react-cookie';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }): JSX.Element => {
  const { pathname } = useLocation();
  const { visibleAlert, showAlert } = useModal();
  const [cookies] = useCookies(['cookies-accept']);

  // display cookie policy modal
  useEffect(() => {
    if (cookies['cookies-accept'] === undefined) {
      showAlert({
        content: <CookiesModalContent />,
        showConfirm: false
      });
    }
  }, [cookies]);

  return (
    <>
      {['/fr', '/en'].includes(pathname) ? (
        <>
          <main>{children}</main>
          {visibleAlert && <Modal visibleAlert={visibleAlert} />}
        </>
      ) : (
        <>
          <Header />
          <main className="padding-menu">{children}</main>
          {visibleAlert && <Modal visibleAlert={visibleAlert} />}
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
