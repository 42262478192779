import { Icons } from 'assets/icons';
import useModal from 'components/modal/useModal';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import 'assets/style/cookies-modal.scss';
import { NavLink } from 'react-router-dom';
import Button from 'components/button/Button';

const CookiesModalContent: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { clearAlert } = useModal();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['cookies-accept']);

  const onClick = (accept = false) => {
    const now = new Date();
    const next30days = new Date(now.setDate(now.getDate() + 30));
    setCookie('cookies-accept', accept, { path: '/', expires: next30days });
    clearAlert();
  };

  return (
    <div className="cookie-modal">
      <span className="cookie-modal__cancel" role={'button'} onClick={() => onClick(false)}>
        {t('cookies.modal.cancel')}
      </span>
      <div className="cookie-modal__icon">{Icons.LogoFacelytics()}</div>
      <div className="cookie-modal__text">
        <h2 className="cookie-modal__title">{t('cookies.modal.title')}</h2>
        <p className="cookie-modal__content">
          {t('cookies.modal.content')}
          <NavLink to="/terms-of-use" className="cookie-modal__content--link">
            {t('cookies.modal.content-link')}
          </NavLink>
        </p>
      </div>
      <Button
        className="btn btn--thin btn--large btn--color"
        onClick={() => onClick(true)}
        title={t('cookies.modal.confirm')}
      />
    </div>
  );
};

export default CookiesModalContent;
