import React, { Suspense } from 'react';
import Router from 'routes/router';
import { ModalProvider } from 'components/modal/modalContext';
import { DemoProvider } from 'contexts/demoContext';
import { RecoilRoot } from 'recoil';
import Compose from 'routes/Compose';
import LoadingPage from './pages/loading/Loading';

const App: React.FC = () => (
  <Suspense fallback={<LoadingPage />}>
    <Compose providers={[RecoilRoot, ModalProvider, DemoProvider]}>
      <Router />
    </Compose>
  </Suspense>
);
export default App;
