/* eslint-disable import/named */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultHeaderLinks,
  faceAttributestHeaderLinks,
  faceRecognitiontHeaderLinks
} from 'components/header/data';
import useWindowDimensions from 'hooks/useWindowDimensions';
import 'assets/style/header.scss';
import 'assets/style/link.scss';
import 'assets/style/button.scss';
import 'assets/style/dropdown.scss';
import { Icons } from 'assets/icons';
import { useLocation } from 'react-router-dom';
import { randomKeyGen } from 'utils/tools';
import useEventListener from 'hooks/useEventListener';
import { languagesAvailable } from 'i18nextInit';
import { changeLanguage } from 'i18next';
import Dropdown from 'components/dropdown/Dropdown';
import RenderNavHashLink from 'components/navHashLink/NavHashLink';
import { useRecoilValue } from 'recoil';
import { websiteAtom } from 'recoil/atoms/websiteAtom';

type RenderLinksProps = {
  toggle: () => void;
};

const Header: React.FC = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { width } = useWindowDimensions();
  const [scroll, setScroll] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const website = useRecoilValue(websiteAtom);

  // auto-close menu if resize
  useEffect(() => {
    if (width > 991 && isMenuOpen) setIsMenuOpen(false);
  }, [width]);

  // Add class `scroll` to header
  const handleScroll = useCallback(() => {
    setScroll(window.scrollY > 50 ? 'scroll' : '');
  }, [window]);

  // Check `Scroll` state
  useEventListener('scroll', handleScroll);

  // Handle menu toggle (mobile only)
  const handleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLanguage = (language?: string) => {
    changeLanguage(language).then(() => {
      const { location } = window;
      if (
        location.pathname.match(/\/fr-FR|\/fr|\/en/) &&
        !location.pathname.includes(`/${language}`)
      ) {
        const nextURL = location.pathname.replace(/\/fr-FR|\/fr|\/en/, `/${language}`);
        location.replace(nextURL);
      }
    });
  };

  // Change logo color according to `pathname`
  const handleLogoClass = () => {
    if (
      pathname.substring(1).includes('face-attributes/api') ||
      pathname.substring(1).includes('face-recognition/api') ||
      pathname.substring(1).includes('face-attributes/pricing') ||
      pathname.substring(1).includes('face-recognition/pricing')
    ) {
      return 'api';
    } else {
      return pathname.replace(/\/fr-FR|\/fr|\/en/, ``).substring(1);
    }
  };

  // Change links color according to `pathname`
  const handleSecondary = (path: string) => {
    let secondary = '';

    switch (path) {
      case 'en/face-attributes/api':
      case 'fr/face-attributes/api':
        secondary = ' header--secondary';
        break;

      case 'en/face-recognition/api':
      case 'fr/face-recognition/api':
        secondary = ' header--secondary';
        break;

      case 'en/face-attributes/pricing':
      case 'fr/face-attributes/pricing':
        secondary = ' header--secondary';
        break;

      case 'en/face-recognition/pricing':
      case 'fr/face-recognition/pricing':
        secondary = ' header--secondary';
        break;

      case 'en/pricing':
      case 'fr/pricing':
        secondary = ' header--secondary';
        break;

      case 'en/terms-of-use':
      case 'fr/terms-of-use':
        secondary = ' header--secondary';
        break;

      case 'en/privacy-policy':
      case 'fr/privacy-policy':
        secondary = ' header--secondary';
        break;

      case 'en/cookies-policy':
      case 'fr/cookies-policy':
        secondary = ' header--secondary';
        break;

      default:
        return '';
    }

    return secondary;
  };

  const RenderLanguages = () => (
    <>
      {languagesAvailable.map((lng) => (
        <button
          key={randomKeyGen()}
          className={`link--sub-menu language__item ${
            currentLanguage?.includes(lng) ? 'language__item--active' : ''
          }`}
          onClick={() => currentLanguage !== lng && handleLanguage(lng)}
        >
          <span>{t(`language.${lng}.name`)}</span>
        </button>
      ))}
    </>
  );

  return (
    <header
      className={`header ${scroll || isMenuOpen ? 'header--scroll' : ''}${handleSecondary(
        pathname.substring(1)
      )}`}
    >
      <div className="container__header">
        <div className="header__content">
          <div className="logo">
            <RenderNavHashLink
              to={`/${currentLanguage}`}
              title={t('header.logo.title')}
              className="logo__link"
            >
              <div className={`logo__image ${handleLogoClass()}`} />
            </RenderNavHashLink>
          </div>
          <nav className={`menu ${isMenuOpen ? 'menu--open' : ''}`} role="navigation">
            <div className="lng">
              {languagesAvailable.map((lng: string | undefined) => (
                <button
                  key={randomKeyGen()}
                  type="button"
                  className={`lng__btn ${currentLanguage === lng ? 'lng__btn--active' : ''}`}
                  onClick={() => currentLanguage !== lng && handleLanguage(lng)}
                >
                  <span>{t(`language.${lng}.key`).toUpperCase()}</span>
                </button>
              ))}
            </div>
            <RenderLinks toggle={() => isMenuOpen && handleMenu()} />
          </nav>
          <div className="btn">
            <a
              href={`${
                process.env.REACT_APP_URL_WIS
              }subscription/facelytics/trial?origin=${website.name.replace('-', '_')}`}
              target="_blank"
              rel="noreferrer"
              className={`btn__link btn__link--demo btn btn--thin btn--xlarge ${
                pathname?.length > 3 && !(scroll || isMenuOpen) ? 'btn--trace' : 'btn--trace'
              }`}
            >
              {t('header.menu.trial')}
            </a>
            <a
              href={process.env.REACT_APP_URL_MANAGER}
              target="_blank"
              rel="noreferrer"
              className={`nav__link nav__link--login btn btn--thin btn--xlarge ${
                scroll || isMenuOpen ? 'btn--color' : 'btn--color'
              }${['/face-recognition'].includes(pathname) ? ' btn--secondary-white' : ''}
              ${
                (['/face-recognition'].includes(pathname) && scroll) || isMenuOpen
                  ? ' btn--secondary-color'
                  : ''
              }`}
              title={t('header.menu.login')}
            >
              {t('header.menu.login')}
            </a>
          </div>
          <button type="button" className="header__icon" onClick={handleMenu}>
            {isMenuOpen
              ? Icons.IconClose({ color: '#009BDE', size: 26 })
              : Icons.IconMenu({ color: 'black', size: 26 })}
          </button>
          <Dropdown
            title={Icons.IconLanguage({ size: 26, color: '#ffffff' })}
            toggleClassName={`link language__icon ${
              scroll || isMenuOpen ? 'language__icon--scroll' : ''
            }`}
            className="language__dropdown"
          >
            <RenderLanguages />
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

const RenderLinks: React.FC<RenderLinksProps> = ({ toggle }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const currentLanguage = i18n.language;

  let headerLinks = defaultHeaderLinks;

  // Prise en compte variation du menu en fonction de la page
  if (pathname.includes('face-attributes')) {
    headerLinks = faceAttributestHeaderLinks;
  } else if (pathname.includes('face-recognition')) {
    headerLinks = faceRecognitiontHeaderLinks;
  }

  return (
    <>
      {headerLinks.map((item) => (
        <React.Fragment key={randomKeyGen()}>
          {item?.submenu && (
            <Dropdown title={t(`header.menu.${item.name}`)} toggleClassName="link menu__link">
              {item.submenu.map((item) => (
                <RenderNavHashLink
                  key={randomKeyGen()}
                  to={`${currentLanguage}${item.link}`}
                  className={'link link--sub menu__link menu__link--sub'}
                  onClick={toggle}
                >
                  <span className="link__text">{t(`header.menu.${item.name}`)}</span>
                </RenderNavHashLink>
              ))}
            </Dropdown>
          )}
          {item?.link && (
            <RenderNavHashLink
              key={randomKeyGen()}
              to={`${currentLanguage}${item.link}`}
              className={'link menu__link ' + item.class}
              onClick={toggle}
            >
              {t(`header.menu.${item.name}`)}
            </RenderNavHashLink>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Header;
