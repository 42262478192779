import React from 'react';
import 'assets/style/spinner.scss';

const Loading: React.FC = (): JSX.Element => {
  return (
    <div id="spinner-overlay">
      <div className="spinner" />
    </div>
  );
};

export default Loading;
