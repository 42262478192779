import React from 'react';
import { useTranslation } from 'react-i18next';
import 'assets/style/footer.scss';
import { Link } from 'react-router-dom';
import { mediaData, navData } from 'components/footer/data';
import { randomKeyGen } from 'utils/tools';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <footer className="container">
      <div className="wis">
        <ul className="social-media">
          {mediaData.map((social) => (
            <li key={randomKeyGen()} className="social-media__item">
              <a href={social.href} title={social.name} rel="noreferrer" target="_blank">
                {social.icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ul className="nav">
        {navData(t).map((nav) => (
          <li key={randomKeyGen()} className="nav__item">
            {nav.external ? (
              <a href={nav.href} title={nav.title} rel="noreferrer" target="_blank">
                {nav.title}
              </a>
            ) : (
              <Link className="nav__link" to={`/${currentLanguage}${nav.href}`}>
                {nav.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
