/* eslint-disable import/prefer-default-export */
export const randomKeyGen = (): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 15; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const convertSize = (size: number): string => {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const isEmpty = (object: { [s: string]: unknown }): boolean =>
  Object.values(object).every((x) => x === null || x === undefined);

export const randomNumberTimeout = (min: number, max: number): number => {
  return (Math.random() * max + min) * 1000;
};

export const validateRegex = (str: string, regex: RegExp): boolean => {
  return regex.test(str);
};

// Add offset to NavHashLink
export const scrollWidthOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -64; // header's height
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};
