import Loading from 'components/loading/Loading';
import React from 'react';
import 'assets/style/loading-page.scss';

const LoadingPage: React.FC = (): JSX.Element => (
  <main className="loading-page">
    <Loading />
  </main>
);

export default LoadingPage;
