import React, { CSSProperties } from 'react';
import { ReactComponent as GenderSvg } from 'assets/icons/icon_gender.svg';
import { ReactComponent as AgeSvg } from 'assets/icons/icon_age.svg';
import { ReactComponent as MaskSvg } from 'assets/icons/icon_mask.svg';
import { ReactComponent as CountingSvg } from 'assets/icons/icon_counting.svg';
import { ReactComponent as AnalyticsSvg } from 'assets/icons/icon_analytics.svg';
import { ReactComponent as FaceSvg } from 'assets/icons/icon_face.svg';
import { ReactComponent as FaceSearchSvg } from 'assets/icons/icon_face_search.svg';
import { ReactComponent as FaceRecognitionSvg } from 'assets/icons/icon_face_recognition.svg';
import { ReactComponent as FaceAttributeSvg } from 'assets/icons/icon_face_attribute.svg';
import { ReactComponent as CreateDatabaseSvg } from 'assets/icons/icon_create_database.svg';
import { ReactComponent as DatabaseSvg } from 'assets/icons/icon_database.svg';
import { ReactComponent as CarretSvg } from 'assets/icons/icon_carret.svg';

import { ReactComponent as WisTextWhiteSvg } from 'assets/icons/logos/logo_wis_text_white.svg';

// Step Demo
import { ReactComponent as UploadDemoSvg } from 'assets/icons/icon_upload.svg';
import { ReactComponent as SubmitDemoSvg } from 'assets/icons/icon_submit.svg';
import { ReactComponent as ResultDemoSvg } from 'assets/icons/icon_result.svg';
// Result Demo
import { ReactComponent as GenderMaleResultDemoSvg } from 'assets/icons/icon_result_men.svg';
import { ReactComponent as GenderFemaleResultDemoSvg } from 'assets/icons/icon_result_women.svg';
import { ReactComponent as MaskResultDemoSvg } from 'assets/icons/icon_result_mask.svg';
import { ReactComponent as NoMaskResultDemoSvg } from 'assets/icons/icon_result_no_mask.svg';
import { ReactComponent as AgeResultDemoSvg } from 'assets/icons/icon_result_age.svg';
//
import { ReactComponent as CloseMenuSvg } from 'assets/icons/icon_close_color.svg';
import { ReactComponent as MenuSvg } from 'assets/icons/icon_menu.svg';
import { ReactComponent as PlaySvg } from 'assets/icons/icon_play.svg';
import { ReactComponent as ApiSvg } from 'assets/icons/icon_api.svg';
import { ReactComponent as ApiDatabaseSvg } from 'assets/icons/icon_api_database.svg';
import { ReactComponent as ApiFaceSearchSvg } from 'assets/icons/icon_api_face_seach.svg';
import { ReactComponent as ApiFaceCloudSearchSvg } from 'assets/icons/icon_api_cloud_face_seach.svg';
import { ReactComponent as ApiFaceRecognitionchSvg } from 'assets/icons/icon_api_face_recognition.svg';
import { ReactComponent as SdkSvg } from 'assets/icons/icon_sdk.svg';
import { ReactComponent as OnlineSvg } from 'assets/icons/icon_platform.svg';
import { ReactComponent as DashboardSvg } from 'assets/icons/icon_dashboard.svg';
import { ReactComponent as AccountSvg } from 'assets/icons/icon_account.svg';
import { ReactComponent as Face } from 'assets/icons/face.svg';
import { ReactComponent as Plate } from 'assets/icons/plate.svg';
import { ReactComponent as AfterEffectExport } from 'assets/icons/after-effect-export.svg';
import { ReactComponent as CreateDetection } from 'assets/icons/create-detection.svg';
import { ReactComponent as BlurringToolSvg } from 'assets/icons/icon_taskmanager.svg';
import { ReactComponent as ServicesAvg } from 'assets/icons/icon_services.svg';
import { ReactComponent as CollecteDataSvg } from 'assets/icons/icon_collect_data.svg';
import { ReactComponent as RgpdSvg } from 'assets/icons/icon_rgpd.svg';
import { ReactComponent as BouyguesSvg } from 'assets/icons/references/logo_bouygues.svg';
import { ReactComponent as CeesarSvg } from 'assets/icons/references/logo_ceesar.svg';
import { ReactComponent as RenaultSvg } from 'assets/icons/references/logo_renault.svg';
import { ReactComponent as SogeSvg } from 'assets/icons/references/logo_soge.svg';
import { ReactComponent as UgeSvg } from 'assets/icons/references/logo_uge.svg';
import { ReactComponent as Facebook } from 'assets/icons/social/social-facebook.svg';
import { ReactComponent as Twitter } from 'assets/icons/social/social-twitter.svg';
import { ReactComponent as Linkedin } from 'assets/icons/social/social-linkedin.svg';
import { ReactComponent as Github } from 'assets/icons/social/social-github.svg';
import { ReactComponent as SpinSvg } from 'assets/icons/loading_spin.svg';
import { ReactComponent as FileSvg } from 'assets/icons/icon_file.svg';
import { ReactComponent as CheckSvg } from 'assets/icons/icon_input_check.svg';
import { ReactComponent as TickSvg } from 'assets/icons/icon_check_white.svg';
import { ReactComponent as BluritSvg } from 'assets/icons/logo_blurit.svg';
import { ReactComponent as FacelyticsSvg } from 'assets/icons/logo_facelytics_by_wassa.svg';
import { ReactComponent as BluritWhiteSvg } from 'assets/icons/logo_blurit_white.svg';
import { ReactComponent as TableCheck } from 'assets/icons/table/icon_table_check.svg';
import { ReactComponent as TableCross } from 'assets/icons/table/icon_table_cross.svg';
import { ReactComponent as IntegrationApiSvg } from 'assets/icons/integration/logo_api.svg';
import { ReactComponent as IntegrationSdkSvg } from 'assets/icons/integration/logo_sdk.svg';
import { ReactComponent as IntegrationOnPremiseSvg } from 'assets/icons/integration/logo_on-premise.svg';
import { ReactComponent as IntegrationCreateSvg } from 'assets/icons/integration/icon-create.svg';
import { ReactComponent as IntegrationIntegrateSvg } from 'assets/icons/integration/icon-integrate.svg';
import { ReactComponent as IntegrationConfigureSvg } from 'assets/icons/integration/icon-configure.svg';
import { ReactComponent as IntegrationEnjoySvg } from 'assets/icons/integration/icon-enjoy.svg';
import { ReactComponent as IntegrationCreateWhiteSvg } from 'assets/icons/integration/icon-create-white.svg';
import { ReactComponent as IntegrationIntegrateWhiteSvg } from 'assets/icons/integration/icon-integrate-white.svg';
import { ReactComponent as IntegrationConfigureWhiteSvg } from 'assets/icons/integration/icon-configure-white.svg';
import { ReactComponent as IntegrationEnjoyWhiteSvg } from 'assets/icons/integration/icon-enjoy-white.svg';
import { ReactComponent as SelfieSvg } from 'assets/icons/icon_selfie.svg';
import { ReactComponent as LanguageSvg } from 'assets/icons/language.svg';
import { ReactComponent as DetectIcon } from 'assets/icons/icon_detect.svg';
import { ReactComponent as APICloudDatabaseSvg } from 'assets/icons/icon_api_cloud_database.svg';
import { ReactComponent as ApiCloudFaceRecognitionchSvg } from 'assets/icons/icon_api_cloud_face_recognition.svg';
import { ReactComponent as OnlineWis } from 'assets/icons/icon_online_wis.svg';

// Buttons
import { ReactComponent as DownloadSvg } from 'assets/icons/icon_download.svg';
import { ReactComponent as RetrySvg } from 'assets/icons/icon_retry.svg';

// Advantages
import { ReactComponent as Bulk } from 'assets/icons/advantages/bulk.svg';
import { ReactComponent as PositionRetrieval } from 'assets/icons/advantages/position-retrieval.svg';
import { ReactComponent as AllResolution } from 'assets/icons/advantages/all-resolution.svg';
import { ReactComponent as AccurateBlur } from 'assets/icons/advantages/accurate-blur.svg';
import { ReactComponent as Speed } from 'assets/icons/advantages/speed.svg';
import { ReactComponent as Support } from 'assets/icons/advantages/support.svg';
import { ReactComponent as SecurityCompliance } from 'assets/icons/advantages/security-compliance.svg';
import { ReactComponent as VolumeManagement } from 'assets/icons/advantages/volume-management.svg';
import { ReactComponent as MultiEthnique } from 'assets/icons/advantages/multiethnique.svg';
import { ReactComponent as AllAge } from 'assets/icons/advantages/all-age.svg';

// Features
import { ReactComponent as CreateDatabase } from 'assets/icons/icon_create-database.svg';
import { ReactComponent as FaceRecognition } from 'assets/icons/icon_face-recognition.svg';
import { ReactComponent as IdentityConfirmation } from 'assets/icons/icon_identity-confirmation.svg';

//Integration
import { ReactComponent as IntegrationOnlinePlatformSvg } from 'assets/icons/integration/logo_online-platform.svg';

//Logo
import { ReactComponent as LogoFacelyticsWhite } from 'assets/images/logo_facelytics_by_wassa_white.svg';

export interface IconParams {
  color?: string;
  size?: number;

  width?: number;
  height?: number;
  style?: CSSProperties;
}

const defaultIconParams: IconParams = {
  color: 'black',
  style: { width: '100%', height: 'auto' }
};

export const Icons = {
  IconFile: (params = defaultIconParams): JSX.Element => (
    <FileSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconCarret: (params = defaultIconParams): JSX.Element => (
    <CarretSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconUploadDemo: (params = defaultIconParams): JSX.Element => (
    <UploadDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconSubmitDemo: (params = defaultIconParams): JSX.Element => (
    <SubmitDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconResultDemo: (params = defaultIconParams): JSX.Element => (
    <ResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconGenderMaleResultDemo: (params = defaultIconParams): JSX.Element => (
    <GenderMaleResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconGenderFemaleResultDemo: (params = defaultIconParams): JSX.Element => (
    <GenderFemaleResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconAgeResultDemo: (params = defaultIconParams): JSX.Element => (
    <AgeResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconMaskResultDemo: (params = defaultIconParams): JSX.Element => (
    <MaskResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconNoMaskResultDemo: (params = defaultIconParams): JSX.Element => (
    <NoMaskResultDemoSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconFaceSearch: (params = defaultIconParams): JSX.Element => (
    <FaceSearchSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconFaceRecognition: (params = defaultIconParams): JSX.Element => (
    <FaceRecognitionSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconFaceAttribute: (params = defaultIconParams): JSX.Element => (
    <FaceAttributeSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconCreateDatabase: (params = defaultIconParams): JSX.Element => (
    <CreateDatabaseSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconDatabase: (params = defaultIconParams): JSX.Element => (
    <DatabaseSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  Bulk: (params = defaultIconParams): JSX.Element => (
    <Bulk fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  PositionRetrieval: (params = defaultIconParams): JSX.Element => (
    <PositionRetrieval
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  AllResolution: (params = defaultIconParams): JSX.Element => (
    <AllResolution
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  AccurateBlur: (params = defaultIconParams): JSX.Element => (
    <AccurateBlur
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  Speed: (params = defaultIconParams): JSX.Element => (
    <Speed fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Support: (params = defaultIconParams): JSX.Element => (
    <Support fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  SecurityCompliance: (params = defaultIconParams): JSX.Element => (
    <SecurityCompliance
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  VolumeManagement: (params = defaultIconParams): JSX.Element => (
    <VolumeManagement
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  MultiEthnique: (params = defaultIconParams): JSX.Element => (
    <MultiEthnique
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  AllAGe: (params = defaultIconParams): JSX.Element => (
    <AllAge fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  CreateDatabase: (params = defaultIconParams): JSX.Element => (
    <CreateDatabase
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  FaceRecognition: (params = defaultIconParams): JSX.Element => (
    <FaceRecognition
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IdentityConfirmation: (params = defaultIconParams): JSX.Element => (
    <IdentityConfirmation
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconGender: (params = defaultIconParams): JSX.Element => (
    <GenderSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconAge: (params = defaultIconParams): JSX.Element => (
    <AgeSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconMask: (params = defaultIconParams): JSX.Element => (
    <MaskSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconCounting: (params = defaultIconParams): JSX.Element => (
    <CountingSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconAnalytics: (params = defaultIconParams): JSX.Element => (
    <AnalyticsSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconFace: (params = defaultIconParams): JSX.Element => (
    <FaceSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconClose: (params = defaultIconParams): JSX.Element => (
    <CloseMenuSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconMenu: (params = defaultIconParams): JSX.Element => (
    <MenuSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconPlay: (params = defaultIconParams): JSX.Element => (
    <PlaySvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconApi: (params = defaultIconParams): JSX.Element => (
    <ApiSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconApiDatabase: (params = defaultIconParams): JSX.Element => (
    <ApiDatabaseSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconApiFaceSearch: (params = defaultIconParams): JSX.Element => (
    <ApiFaceSearchSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconApiRecognitionSearch: (params = defaultIconParams): JSX.Element => (
    <ApiFaceRecognitionchSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconSdk: (params = defaultIconParams): JSX.Element => (
    <SdkSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconOnline: (params = defaultIconParams): JSX.Element => (
    <OnlineSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconDashboard: (params = defaultIconParams): JSX.Element => (
    <DashboardSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconAccount: (params = defaultIconParams): JSX.Element => (
    <AccountSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconServices: (params = defaultIconParams): JSX.Element => (
    <ServicesAvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconBlurringTool: (params = defaultIconParams): JSX.Element => (
    <BlurringToolSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconCollectData: (params = defaultIconParams): JSX.Element => (
    <CollecteDataSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconRgpd: (params = defaultIconParams): JSX.Element => (
    <RgpdSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoBouygues: (params = defaultIconParams): JSX.Element => (
    <BouyguesSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  LogoCeesar: (params = defaultIconParams): JSX.Element => (
    <CeesarSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoRenault: (params = defaultIconParams): JSX.Element => (
    <RenaultSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoSoge: (params = defaultIconParams): JSX.Element => (
    <SogeSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoUge: (params = defaultIconParams): JSX.Element => (
    <UgeSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Facebook: (params = defaultIconParams): JSX.Element => (
    <Facebook fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Twitter: (params = defaultIconParams): JSX.Element => (
    <Twitter fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Linkedin: (params = defaultIconParams): JSX.Element => (
    <Linkedin fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Github: (params = defaultIconParams): JSX.Element => (
    <Github fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  SpinIcon: (params = defaultIconParams): JSX.Element => (
    <SpinSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  CheckIcon: (params = defaultIconParams): JSX.Element => (
    <CheckSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  TickIcon: (params = defaultIconParams): JSX.Element => (
    <TickSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoBlurit: (params = defaultIconParams): JSX.Element => (
    <BluritSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoFacelytics: (params = defaultIconParams): JSX.Element => (
    <FacelyticsSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  LogoBluritWhite: (params = defaultIconParams): JSX.Element => (
    <BluritWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  LogoWisTextWhite: (params = defaultIconParams): JSX.Element => (
    <WisTextWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  TableCheck: (params = defaultIconParams): JSX.Element => (
    <TableCheck fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  TableCross: (params = defaultIconParams): JSX.Element => (
    <TableCross fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconIntegrationOnlinePlatform: (params = defaultIconParams): JSX.Element => (
    <IntegrationOnlinePlatformSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationApi: (params = defaultIconParams): JSX.Element => (
    <IntegrationApiSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationSdkSvg: (params = defaultIconParams): JSX.Element => (
    <IntegrationSdkSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationOnPremise: (params = defaultIconParams): JSX.Element => (
    <IntegrationOnPremiseSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  Face: (params = defaultIconParams): JSX.Element => (
    <Face fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  Plate: (params = defaultIconParams): JSX.Element => (
    <Plate fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  AfterEffectExport: (params = defaultIconParams): JSX.Element => (
    <AfterEffectExport
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  CreateDetection: (params = defaultIconParams): JSX.Element => (
    <CreateDetection
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationCreate: (params = defaultIconParams): JSX.Element => (
    <IntegrationCreateSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationIntegrate: (params = defaultIconParams): JSX.Element => (
    <IntegrationIntegrateSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationConfigure: (params = defaultIconParams): JSX.Element => (
    <IntegrationConfigureSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationEnjoy: (params = defaultIconParams): JSX.Element => (
    <IntegrationEnjoySvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationCreateWhite: (params = defaultIconParams): JSX.Element => (
    <IntegrationCreateWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationIntegrateWhite: (params = defaultIconParams): JSX.Element => (
    <IntegrationIntegrateWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationConfigureWhite: (params = defaultIconParams): JSX.Element => (
    <IntegrationConfigureWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconIntegrationEnjoyWhite: (params = defaultIconParams): JSX.Element => (
    <IntegrationEnjoyWhiteSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconLanguage: (params = defaultIconParams): JSX.Element => (
    <LanguageSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconSelfie: (params = defaultIconParams): JSX.Element => (
    <SelfieSvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconRetry: (params = defaultIconParams): JSX.Element => (
    <RetrySvg fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconDownload: (params = defaultIconParams): JSX.Element => (
    <DownloadSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconDetect: (params = defaultIconParams): JSX.Element => (
    <DetectIcon fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  IconApiCloudDatabase: (params = defaultIconParams): JSX.Element => (
    <APICloudDatabaseSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconApiFaceCloudSearchSvg: (params = defaultIconParams): JSX.Element => (
    <ApiFaceCloudSearchSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconApiCloudFaceRecognitionchSvg: (params = defaultIconParams): JSX.Element => (
    <ApiCloudFaceRecognitionchSvg
      fill={params.color}
      height={params.size}
      width={params.size}
      style={params.style}
    />
  ),
  IconOnlineWis: (params = defaultIconParams): JSX.Element => (
    <OnlineWis fill={params.color} height={params.size} width={params.size} style={params.style} />
  ),
  LogoFacelyticsWhite: (params = defaultIconParams): JSX.Element => (
    <LogoFacelyticsWhite
      fill={params.color}
      height={params.height}
      width={params.width}
      style={params.style}
    />
  )
};
