import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertProps } from 'react-bootstrap-sweetalert/dist/types';
import { CustomAlertProps } from './modalContext';
import 'assets/style/modal.scss';

type ModalProps = {
  visibleAlert: SweetAlertProps & CustomAlertProps;
};

const Modal: React.FC<ModalProps> = ({ visibleAlert }): JSX.Element => {
  const customClass = visibleAlert?.customClass || 'modal';
  const confirmBtnCssClass = visibleAlert?.confirmBtnCssClass || 'btn btn--thin btn--color';
  const cancelBtnCssClass = visibleAlert?.cancelBtnCssClass || 'btn btn--thin btn--trace';

  return (
    <SweetAlert
      customClass={customClass}
      confirmBtnCssClass={confirmBtnCssClass}
      cancelBtnCssClass={cancelBtnCssClass}
      // We disable no spreading eslint rule because there are too many props
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...visibleAlert}
    >
      {visibleAlert?.content && <div className="modal__message">{visibleAlert?.content}</div>}
    </SweetAlert>
  );
};

export default Modal;
