import React, { useState, useEffect, useRef } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import 'assets/style/dropdown.scss';
import useOnClickOutside from 'hooks/useOnClickOutside';

type DropdownProps = {
  title: string | React.ReactNode;
  className?: string;
  toggleClassName?: string;
  contentClassName?: string;
  closeOnClick?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  title,
  children,
  className,
  toggleClassName,
  contentClassName,
  closeOnClick
}): JSX.Element => {
  const dropdownRef = useRef(null);
  const { width } = useWindowDimensions();
  const [dropVisible, setDropVisible] = useState(false);

  // auto-close dropdown if resize
  useEffect(() => {
    if (width < 991 && dropVisible) setDropVisible(false);
  }, [width]);

  // Handle dropdown toggle
  const handleDropdown = () => setDropVisible(!dropVisible);

  useOnClickOutside(dropdownRef, () => setDropVisible(false));

  const containerClass = `dropdown ${className}`;
  const toggleClass = `dropdown__title ${toggleClassName} ${
    dropVisible ? 'dropdown__title--active' : ''
  }`;
  const contentClass = `dropdown__content ${contentClassName} ${
    dropVisible ? 'dropdown__content--visible' : ''
  }`;

  return (
    <div className={containerClass} ref={dropdownRef}>
      <span onClick={handleDropdown} className={toggleClass}>
        {title}
      </span>
      <div className={contentClass} onClick={() => closeOnClick && handleDropdown()}>
        {children}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  title: '',
  className: '',
  toggleClassName: '',
  contentClassName: '',
  closeOnClick: true
};

export default Dropdown;
