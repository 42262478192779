/* eslint-disable import/named */
import React from 'react';
import { NavHashLink, NavHashLinkProps } from 'react-router-hash-link';
import { scrollWidthOffset } from 'utils/tools';

const RenderNavHashLink: React.FC<NavHashLinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <NavHashLink smooth scroll={scrollWidthOffset} {...props}>
      {children}
    </NavHashLink>
  );
};

export default RenderNavHashLink;
