import { atom } from 'recoil';

/**
 * @returns name: String representing each site used in translations ('face-attributes' = faces attributes / 'face-recognition' = face recognition)
 * @returns secondary: Boolean for each site used in style (false = faces attributes / true = face recognition)
 */
const websiteAtom = atom({
  key: 'auth',
  default: {
    name: 'face-attributes',
    secondary: false
  }
});

export { websiteAtom };
