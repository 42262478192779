import SliderImage from 'assets/images/slider.jpg';
import SliderImageBlurred from 'assets/images/slider_blurred.jpg';
import BlurPlates from 'assets/images/plates_background.png';
import BlurHeads from 'assets/images/face_background.jpg';
import BlurArea from 'assets/images/area_blurred.jpg';
import VideoPlayerPoster from 'assets/images/background_video.png';
import LogoWis from 'assets/images/logo_wis.png';
import LogoWisWhite from 'assets/images/logo_wis_white.svg';
import LogoWisBlack from 'assets/images/logo_wis_black.svg';
import LogoBlurit from 'assets/images/logo_blurit.png';
import LogoBluritWhite from 'assets/images/logo_blurit_white.png';
import LogoFacelytics from 'assets/images/logo_facelytics_by_wassa.svg';
import LogoFacelyticsWhite from 'assets/images/logo_facelytics_by_wassa_white.svg';
import Demo1 from 'assets/images/demo_1.jpg';
import Demo1Blurred from 'assets/images/demo_1_blurred.jpg';
import Demo3 from 'assets/images/demo_3.jpg';
import Demo3Blurred from 'assets/images/demo_3.jpg';
import Blurit from 'assets/images/landing/blurit2.jpg';
import Facelytics from 'assets/images/landing/facelytics.jpg';
import Wis from 'assets/images/landing/wis.jpg';
import Picslytics from 'assets/images/landing/pixlytics.jpg';
import IntegrationCreateSvg from 'assets/icons/integration/icon-create.svg';
import IntegrationIntegrateSvg from 'assets/icons/integration/icon-integrate.svg';
import IntegrationConfigureSvg from 'assets/icons/integration/icon-configure.svg';
import IntegrationEnjoySvg from 'assets/icons/integration/icon-enjoy.svg';
import LandingDefaultsBg from 'assets/images/landing/background-default.jpg';
import LandingFaceAttributesBg from 'assets/images/landing/background-attributes.jpg';
import LandingFaceRecognitionBg from 'assets/images/landing/background-recognition.jpg';

import ApiSchema1 from 'assets/images/integration/api_schema_1.png';
import ApiSchema1FR from 'assets/images/integration/api_schema_1_fr.png';
import ApiSchema2 from 'assets/images/integration/api_schema_2.png';
import ApiSchema3 from 'assets/images/integration/api_schema_3.png';
import ApiSchema3FR from 'assets/images/integration/api_schema_3_fr.png';
import ApiSchemaMobile1 from 'assets/images/integration/api_schema_mobile_1.png';
import ApiSchemaMobile1FR from 'assets/images/integration/api_schema_mobile_1_fr.png';
import ApiSchemaMobile2 from 'assets/images/integration/api_schema_mobile_2.png';
import ApiSchemaMobile2FR from 'assets/images/integration/api_schema_mobile_2_fr.png';
import ApiSchemaMobile3 from 'assets/images/integration/api_schema_mobile_3.png';
import ApiSchemaMobile3FR from 'assets/images/integration/api_schema_mobile_3_fr.png';

// Integration
import IntegrationWisSchema from 'assets/images/integration/wis_schema.png';
import TaskStep1 from 'assets/images/integration/step1.jpg';
import TaskStep2 from 'assets/images/integration/step2.jpg';
import TaskStep3 from 'assets/images/integration/step3.jpg';

// Advantages
import Advantages from 'assets/images/advantage.webp';

export const Images = {
  SliderImage: SliderImage,
  SliderImageBlurred: SliderImageBlurred,
  SliderVideo: 'https://media.giphy.com/media/uqMViODVMyMzboLit7/giphy.gif',
  SliderVideoBlurred: 'https://media.giphy.com/media/MmpKUJh5dS9SAPazvg/giphy-downsized-large.gif',
  BlurPlates: BlurPlates,
  BlurHeads: BlurHeads,
  BlurArea: BlurArea,
  PicturesAndVideos:
    'https://s3-alpha-sig.figma.com/img/0eab/d7ac/35ac64d9b64de9e030efd99027749e55?Expires=1646006400&Signature=Js5~sYjmDx1byE0gbI2JmzqNJFotsDKVkg8GY5MoxHxVc2tv83RWDC9Fdz7hwXPtoIaVCSc-bzRUzNxiFEUYukkWekUBlEK97mnBlKDy68EsKqW8Sgs1rAEOzm-8Ck~6VBT3s8KyIItbT5mE2~jh58uXao3kGmvVhlQw7O1vXdQ1KNhOWsZUtdCYuXV0q2pMAsAWovkNNvoH4fARt8DB-0cMrrX9w5oaSyPMiP2U8-78e7uzGzvv9c1hd7Ep8dtDaHHQB-aYzyWo3U7yTJfQ9BB8LY7V~v9ceO~tC1LuKbEZwcZtWW-JH5lgjvO537dVNqyGMysRX5qJkWfjNbBX6g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
  VideoPlayerPoster: VideoPlayerPoster,
  LogoWis: LogoWis,
  LogoWisWhite: LogoWisWhite,
  LogoWisBlack: LogoWisBlack,
  LogoFacelytics: LogoFacelytics,
  LogoFacelyticsWhite: LogoFacelyticsWhite,
  LogoBlurit: LogoBlurit,
  LogoBluritWhite: LogoBluritWhite,
  Demo1: Demo1,
  Demo1Blurred: Demo1Blurred,
  Demo2: 'https://media.giphy.com/media/uqMViODVMyMzboLit7/giphy.gif',
  Demo2Blurred: 'https://media.giphy.com/media/uqMViODVMyMzboLit7/giphy.gif',
  Demo3: Demo3,
  Demo3Blurred: Demo3Blurred,
  Blurit: Blurit,
  Facelytics: Facelytics,
  Wis: Wis,
  Picslytics: Picslytics,
  IntegrationCreateSvg: IntegrationCreateSvg,
  IntegrationIntegrateSvg: IntegrationIntegrateSvg,
  IntegrationConfigureSvg: IntegrationConfigureSvg,
  IntegrationEnjoySvg: IntegrationEnjoySvg,
  LandingDefaultsBg: LandingDefaultsBg,
  LandingFaceAttributesBg: LandingFaceAttributesBg,
  LandingFaceRecognitionBg: LandingFaceRecognitionBg,
  ApiSchema1: ApiSchema1,
  ApiSchema2: ApiSchema2,
  ApiSchema3: ApiSchema3,
  ApiSchema1FR: ApiSchema1FR,
  ApiSchema3FR: ApiSchema3FR,
  ApiSchemaMobile1: ApiSchemaMobile1,
  ApiSchemaMobile2: ApiSchemaMobile2,
  ApiSchemaMobile3: ApiSchemaMobile3,
  ApiSchemaMobile1FR: ApiSchemaMobile1FR,
  ApiSchemaMobile2FR: ApiSchemaMobile2FR,
  ApiSchemaMobile3FR: ApiSchemaMobile3FR,
  Advantages: Advantages,
  TaskStep1: TaskStep1,
  TaskStep2: TaskStep2,
  TaskStep3: TaskStep3,
  IntegrationWisSchema: IntegrationWisSchema
};
