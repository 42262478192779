import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from 'layouts/DefaultLayout';
import Compose from 'routes/Compose';
import { PricingProvider } from 'contexts/pricingContext';
import LoadingPage from 'pages/loading/Loading';
import { ReCaptchaProvider } from '../contexts/googleRecaptchaContext';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import RenderHelmet from '../components/renderHelmet/RenderHelmet';

const Landing = lazy(() => import('pages/landing/Landing'));
const Pricing = lazy(() => import('pages/pricing/Pricing'));
const FaceAttributes = lazy(() => import('pages/face-attributes/FaceAttributes'));
const FaceRecognition = lazy(() => import('pages/face-recognition/FaceRecognition'));
const FaceRecognitionPricing = lazy(
  () => import('pages/face-recognition/pricing/FaceRecognitionPricing')
);
const FaceAttributesPricing = lazy(
  () => import('pages/face-attributes/pricing/FaceAttributesPricing')
);
const Api = lazy(() => import('pages/integration/api/Api'));
const FaceRecognitionApi = lazy(
  () => import('pages/integration/face-recognition-api/FaceRecognitionApi')
);
const FaceRecognitionOnlinePlatform = lazy(
  () => import('pages/integration/face-recognition-online-platform/FaceRecognitionOnlinePlatform')
);
const FaceAttributesOnlinePlatform = lazy(
  () => import('pages/integration/face-attributes-online-platform/FaceAttributesOnlinePlatform')
);
const Sdk = lazy(() => import('pages/integration/sdk/Sdk'));
const Privacy = lazy(() => import('pages/staticPages/privacy/Privacy'));
const Cookies = lazy(() => import('pages/staticPages/cookies/Cookies'));
const Terms = lazy(() => import('pages/staticPages/terms/Terms'));
const Licence = lazy(() => import('pages/staticPages/licence/Licence'));

const Router: React.FC = () => {
  const { i18n } = useTranslation();
  const { location } = window;
  const currentLanguage = i18n.language;
  const pathnameLanguage = location.pathname.split('/')[1];

  // Vérification si la langue chargée par le détecteur de langue de i18n est différente de l'URL courante
  if (pathnameLanguage !== currentLanguage) {
    // Reconstruction de l'URL et mise à jour de l'URL sans redirection
    const nextURL = location.pathname.replace(/\/fr-FR|\/fr|\/en/, `/${currentLanguage}`);
    window.history.pushState({}, '', nextURL);
  }

  return (
    <ReCaptchaProvider>
      <BrowserRouter>
        <ScrollToTop />
        <RenderHelmet name="landing" />
        <Suspense fallback={<LoadingPage />}>
          <Layout>
            <Routes>
              <Route path={`/:locale`} element={<Landing />} />
              <Route path={`/:locale/face-attributes`} element={<FaceAttributes />} />
              <Route path={`/:locale/face-recognition`} element={<FaceRecognition />} />
              <Route path={`/:locale/face-recognition/api`} element={<FaceRecognitionApi />} />
              <Route
                path={`/pricing`}
                element={<Navigate replace to={`/${i18n.language}/pricing`} />}
              />
              <Route
                path={`/:locale/pricing`}
                element={
                  <Compose providers={[PricingProvider]}>
                    <FaceAttributesPricing />
                  </Compose>
                }
              />
              <Route
                path={`/:locale/face-attributes/pricing`}
                element={
                  <Compose providers={[PricingProvider]}>
                    <FaceAttributesPricing />
                  </Compose>
                }
              />
              <Route
                path={`/:locale/face-recognition/pricing`}
                element={
                  <Compose providers={[PricingProvider]}>
                    <FaceRecognitionPricing />
                  </Compose>
                }
              />
              <Route
                path="/:locale/face-attributes/online-platform"
                element={<FaceAttributesOnlinePlatform />}
              />
              <Route
                path="/:locale/face-recognition/online-platform"
                element={<FaceRecognitionOnlinePlatform />}
              />
              <Route path={'/:locale/face-attributes/api'} element={<Api />} />
              <Route path={'/:locale/sdk'} element={<Sdk />} />
              <Route path={'/:locale/privacy-policy'} element={<Privacy />} />
              <Route path={'/:locale/cookies-policy'} element={<Cookies />} />
              <Route path={'/:locale/terms-of-use'} element={<Terms />} />
              <Route path={'/:locale/licence'} element={<Licence />} />
              <Route path="*" element={<Navigate replace to={`/${i18n.language}`} />} />
            </Routes>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </ReCaptchaProvider>
  );
};

export default Router;
